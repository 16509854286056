<template>
  <div>
    <!------------ START: Variables field ------------>
    <InputVariables
      v-if="isVariablesField"
      v-model="value"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      :field="field"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Checkbox ------------>
      <b-form-checkbox
        v-if="field.switch === false"
        v-model="value"
        type="checkbox"
        size="lg"
        :class="validationClass"
        :disabled="isDisabled"
      />
      <!------------ END: Checkbox ------------>
      <!------------ START: Switch ------------>
      <span v-else class="switch">
        <label>
          <input
            v-model="value"
            type="checkbox"
            :class="validationClass"
            :disabled="isDisabled"
          />
          <span></span>
        </label>
      </span>
      <!------------ END: Switch ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import InputVariables from "@/components/Tools/FormHelper/Components/InputVariables";

export default {
  components: { FieldWrapper, InputVariables },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      showPassword: false
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
